<script>
import LoginForm from '@school/components/LoginForm.vue';
import AskPasswordForm from '@school/components/AskPasswordForm.vue';

export default {
  components: { LoginForm, AskPasswordForm },
  data: () => ({ isForgotPassword: false }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
  },
};
</script>

<template>
  <section class="lpage columns m-0 has-background-light is-custom">
    <div class="column is-6 px-3 py-10 is-flex is-justify-content-center is-align-items-center has-text-centered">
      <div class="mw-640">
        <figure v-if="store.logo" class="mx-auto">
          <img
            class="bradius-4"
            :src="store.logo"
            :alt="store.name"
            width="128"
          />
        </figure>
        <h1 class="title is-4 is-size-5-mobile mt-5">
          <template v-if="$store.getters['store/isNotPercentPlan'] && store.feature_options.pages.login.title">
            {{ store.feature_options.pages.login.title }}
          </template>
          <template v-else>
            <span class="is-size-5 is-size-6-mobile has-text-weight-normal">
              Bienvenue sur l'espace de formation
            </span>
            <br>
            {{ store.name }}
          </template>
        </h1>
        <p v-if="$store.getters['store/isNotPercentPlan'] && store.feature_options.pages.login.description">
          {{ store.feature_options.pages.login.description }}
        </p>
      </div>
    </div>
    <div class="column is-6 p-3 is-flex is-justify-content-center is-align-items-center">
      <LoginForm v-show="!isForgotPassword" class="w-full mw-640" @switch="isForgotPassword = true" />
      <AskPasswordForm v-show="isForgotPassword" class="w-full mw-640" @switch="isForgotPassword = false" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.lpage {
  min-height: $school-main-min-height;
}
</style>
