<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/askNewPassword', this.email)
        .then(() => {
          this.$buefy.dialog.alert({
            message: 'Votre demande a été prise en compte, vérifiez votre boîte mail. Pensez à vérifier vos spams si vous ne trouvez pas l\'email.',
            onConfirm: () => this.$router.push({ query: {} }),
          });
        })
        .catch(() => this.$buefy.dialog.alert({
          type: 'is-danger',
          message: 'Nous n\'avons pas trouvé de compte correspondant.',
        }))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="box form">
    <form class="p-5" novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-custom is-4">
        Mot de passe oublié
      </h1>

      <b-field
        label="Adresse email"
        v-bind="$getErrorProps($v.email, ['required', 'email'])"
      >
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field>
        <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading" expanded>
          Envoyer
        </b-button>
      </b-field>

      <b-field class="has-text-centered">
        <b-button type="is-text is-custom" @click="$emit('switch')">
          Me connecter
        </b-button>
      </b-field>
    </form>
  </section>
</template>
